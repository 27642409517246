<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <Overplay v-if="isShow" />
    <!-- <b class="h1">{{ category_name }}</b> -->
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-form-group :label="$t('Name')+ `(*):`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="model.name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Name')"
                  @input="generateSlug(model.name)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="*Slug:" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-form-input
                  v-model="model.slug"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Slug"
                  @input="generateEditSlug(model.slug)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group :label="`*`+$t('Category')+`:`" label-cols-md="12">
              <treeselect
                v-model="model.cat_slug"
                :multiple="false"
                :clearable="true"
                :searchable="true"
                :open-on-click="true"
                :clear-on-select="true"
                :show-count="true"
                :options="items"
                :limit="5"
                :max-height="400"
                @input="handlerChangeCategory"
              />
            </b-form-group>
            <!-- <b-form-group :label="$t('Description')+`:`" label-cols-md="12">
              <b-form-textarea
                v-model="model.content"
                :placeholder="$t('Description')"
              />
            </b-form-group> -->
            <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="2">
              <b-form-checkbox
                v-model="model.is_active"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
              />
            </b-form-group>
          </Fieldset>
          <Fieldset id="SEO" :title="$t('SEO Optimization')">
            <SEOInfo v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset>
            <!-- <Fieldset id="delete" title="Delete">
              <div class="text-right">
                <b-button
                  variant="danger"
                  type="submit"
                  class="text-right"
                  @click.prevent="handleDelete"
                >
                  Delete
                </b-button>
              </div>
            </Fieldset> -->
            <div class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                {{ $t('Update') }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    Treeselect,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      isShow: true,
      model: {
        cat_slug: null,
      },
      activeOptions: Config.activeOptions,
      category_name:
        this.$route.params && this.$route.params.id
          ? this.$route.params.id.split('-')[1]
          : '',
      items: [],
      hasChild: 0,
    }
  },
  watch: {
    '$route.params.id'() {
      this.loadList()
    },
  },
  mounted() {
    this.loadList()
  },
  methods: {
    generateEditSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    generateSlug(name) {
      // this.model.title_page = name
      this.model.slug = this.convertToSlug(name)
      // if (
      //   this.model.cat_slug !== null &&
      //   this.model.cat_slug !== '' &&
      //   this.model.cat_slug !== undefined
      // ) {
      //   this.model.slug = `${this.model.cat_slug}-${this.convertToSlug(name)}`
      // } else {
      //   this.model.slug = this.convertToSlug(name)
      // }
    },
    handlerChangeCategory() {
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
      this.generateSlug(this.model.name)
    },
    async loadDetail(listCat) {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/${
            this.$route.params.id.split('-')[0]
          }?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.hasChild = res.data.data.children.length
            this.isShow = false
            // this.detailDefault = { ...res.data.data }
            // this.model = await this.getDataTranslation(item, false)
            this.model = {
              ...res.data.data,
              // cat_slug: ,
              meta_keyword:
                res.data.data.meta_keyword !== null &&
                res.data.data.meta_keyword !== undefined
                  ? JSON.parse(res.data.data.meta_keyword)
                  : null,
            }
            if (res.data.data.is_active === 1) {
              this.model.is_active = true
            } else {
              this.model.is_active = false
            }
            if (this.$route.query.parent_slug !== undefined) {
              // let temp = {}
              listCat.filter(x => {
                if (x.slug === this.$route.query.parent_slug) {
                  this.model.cat_slug = x.id
                } else {
                  x.children.filter(y => {
                    if (y.slug === this.$route.query.parent_slug) {
                      this.model.cat_slug = y.id
                    }
                  })
                }
              })
              // this.loadCategoryDetail(res.data.data.parent_id)
            }
          } else {
            this.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      this.isShow = true
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.items = []
            const res = result.data.data
            res.map(x => {
              if (x.slug !== this.$route.query.slug) {
                const item = {
                  id: x.slug,
                  label: x.name,
                  slug: x.slug,
                  idx: x.id,
                  children: [],
                }
                if (x.children && x.children.length > 0) {
                  x.children.map(y => {
                    if (y.slug !== this.$route.query.slug) {
                      const i = {
                        id: y.slug,
                        label: y.name,
                        slug: y.slug,
                        idx: y.id,
                      }
                      item.children.push(i)
                    }
                  })
                }
                this.items.push(item)
              }
            })
            this.loadDetail(this.items)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              slug: this.model.slug,
              content: this.model.content,
              avatar: this.model.avatar,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
            }
            if (
              this.model.cat_slug !== '' &&
              this.model.cat_slug !== undefined &&
              this.model.cat_slug !== null
            ) {
              //   if (Array.isArray(this.model.parent_id)) {
              //     params.parent_id = this.model.parent_id[0]
              //   } else {
              params.parent_id = this.model.parent_id
              //   }
            } else {
              params.parent_id = '0'
            }
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/category/${
                this.$route.params.id.split('-')[0]
              }`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Category success',
                  },
                })
                this.$router.push('/product_category/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async handleDelete() {
      try {
        if (this.hasChild <= 0) {
          const res = await Request.delete(
            this.$http,
            `${process.env.VUE_APP_API_URL}/category/${
              this.$route.params.id.split('-')[0]
            }?site_id=${JSON.parse(localStorage.getItem('siteID')).id}`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Congratulation !',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Delete Category success',
                },
              })
              this.$router.push('/product_category/add')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String(this.showError(res.data.error, ',')),
                },
              })
            }
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Cannot delete because this category have children',
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
